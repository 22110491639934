<template>
    <header class="page-title">
        <h1><i class="bi bi-person-circle"></i> アカウント</h1>
    </header>

    <section class="section">
        <div class="alert alert-warning mb-3" v-if="$store.state.auth.is_password_temporary">現在仮パスワードです。パスワードを変更してください。</div>

        <form @submit.prevent="update">
            <div class="row mb-3 align-items-end">
                <div class="col-8">
                    <label class="form-label">氏名</label>
                    <form-input v-model="me.user_name" required></form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">メールアドレス</label>
                    <form-input type="email" v-model="me.email" required></form-input>
                </div>
            </div>
            <div class="row mb-3 align-items-end">
                <div class="col-8">
                    <label class="form-label">パスワード</label>
                    <form-input type="password" v-model="me.password"></form-input>
                    <small>※ 変更する場合のみ入力</small><br>
                    <small>※ 英大文字・英小文字・数字を全て含む8文字以上</small>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">権限</label>
                    <div class="ps-3">
                        <div v-if="permissions.includes(Permission.VIEW_OTHERS_REPORT)"><i class="bi bi-check" style="color: green;"></i> 他人の日報を閲覧</div>
                        <div v-else><i class="bi bi-x"></i> 他人の日報を閲覧</div>
                        <div v-if="permissions.includes(Permission.EDIT_OTHERS_REPORT)"><i class="bi bi-check" style="color: green;"></i> 他人の日報を編集</div>
                        <div v-else><i class="bi bi-x"></i> 他人の日報を編集</div>
                        <div v-if="permissions.includes(Permission.VIEW_PROFIT)"><i class="bi bi-check" style="color: green;"></i> 収支を閲覧</div>
                        <div v-else><i class="bi bi-x"></i> 収支を閲覧</div>
                        <div v-if="permissions.includes(Permission.EDIT_PROFIT)"><i class="bi bi-check" style="color: green;"></i> 収支を編集</div>
                        <div v-else><i class="bi bi-x"></i> 収支を編集</div>
                        <div v-if="permissions.includes(Permission.MANAGE_MASTER)"><i class="bi bi-check" style="color: green;"></i> マスタ管理</div>
                        <div v-else><i class="bi bi-x"></i> マスタ管理</div>
                        <div v-if="permissions.includes(Permission.MANAGE_USER)"><i class="bi bi-check" style="color: green;"></i> ユーザ管理</div>
                        <div v-else><i class="bi bi-x"></i> ユーザ管理</div>
                        <div v-if="permissions.includes(Permission.EDIT_COMPANY)"><i class="bi bi-check" style="color: green;"></i> 会社情報設定</div>
                        <div v-else><i class="bi bi-x"></i> 会社情報設定</div>
                        <div v-if="permissions.includes(Permission.PAY)"><i class="bi bi-check" style="color: green;"></i> 支払</div>
                        <div v-else><i class="bi bi-x"></i> 支払</div>

                    </div>
                </div>
            </div>
            <div class="mt-5">
                <button class="btn btn-info btn-lg">保存</button>
            </div>
        </form>
    </section>
</template>

<script>
import FormInput from '@/components/forms/FormInput';
import Permission from '@/models/enums/permission';

export default {
    name: 'Account',
    components: {
        FormInput,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            me: {
                user_name: this.$store.state.auth.user_name,
                email: this.$store.state.auth.email,
                password: null,
            },
            permissions: this.$store.state.auth.permissions,
            Permission,
        }
    },
    mounted() {
    },
    methods: {
        update() {
            this.startScreenLoading();
            this.$http.put('/me', this.me)
            .then((response) => {
                this.$store.commit('auth/setAccessToken', response.data);
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>
